





















































































import { Component } from 'vue-property-decorator';
import EventsToolbar from './EventsToolbar.vue';

@Component
export default class EventsBottomToolbar extends EventsToolbar {
}
